import { postRequest, getRequest, deleteRequest } from './index'
import { SRCURL } from '@/config'

// 查询收货地址列表
export const tradeReceivingAddressList = (successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/receiving/address/list`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 新增收货地址
export const tradeReceivingAddressSave = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/receiving/address/save`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 更新收货地址
export const tradeReceivingAddressUpdate = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/receiving/address/update`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 设置默认收货地址
export const tradeReceivingAddressSetting = (id, successCallback, failureCallback) => {
  deleteRequest(`${SRCURL}/trade/receiving/address/setting/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 删除收货地址
export const tradeReceivingAddressRemove = (id, successCallback, failureCallback) => {
  deleteRequest(`${SRCURL}/trade/receiving/address/remove/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

